import React, {FC} from "react";
import {I18n, Translate} from "react-redux-i18n";

export interface PDFDopedToolbarProps {
    currentPage: string;
    totalPages: number;
    snap: boolean;
    onCurrentPageChange: (page: string) => void;
    onZoomOut: () => void;
    onZoomIn: () => void;
    onUndo: () => void;
    onRedo: () => void;
    onCreateField: () => void;
    onOpenSidebar: () => void;
    onSetSelectTool: () => void;
    onSetCreateTool: () => void;
    onSetSnap: (snap: boolean) => void;
    onCloneSelected: () => void;
}

export const PDFDopedToolbar: FC<PDFDopedToolbarProps> = ({onCloneSelected, onSetSnap, snap, currentPage, onCurrentPageChange, onCreateField, onOpenSidebar, onRedo, onZoomOut, onZoomIn, onUndo, totalPages, onSetCreateTool, onSetSelectTool}) => {
    return (
        <div className={"toolbar"}>
            <div className="leftButtonSet">
                <button
                    type="button"
                    onClick={() => onZoomOut()}
                    className="tbButton fal fa-minus"
                    title={I18n.t("pdfToolbar.zoomOut")}
                    id="zoomOut"
                ></button>
                <button
                    type="button"
                    onClick={() => onZoomIn()}
                    className="tbButton fal fa-plus"
                    title={I18n.t("pdfToolbar.zoomIn")}
                    id="zoomIn"
                ></button>
                <div className="separator"/>
                <button
                    type="button"
                    onClick={() => onSetSnap(!snap)}
                    className={`tbButton nohover ${snap ? "active" : ""} fal fa-grid-dividers`}
                    title={I18n.t("pdfToolbar.snap")}
                    id="snap"
                ></button>
                <button
                    type="button"
                    onClick={() => onCloneSelected()}
                    className={`tbButton fal fa-clone`}
                    title={I18n.t("pdfToolbar.clone")}
                    id="snap"
                ></button>
            </div>
            <div className="centerButtonSet">
                <label className="tbInputLabel" style={{marginBottom: "0px", marginTop: "0.15rem"}}>
                    <input
                        onKeyUp={(e) => {
                            if (e.key === "Enter") e.preventDefault()
                        }}
                        onFocus={(e) => e.target.select()}
                        onClick={(e) => (e.target as any).select()}
                        onChange={(e) => onCurrentPageChange(e.target.value)}
                        value={currentPage}
                        type="text"
                        title={I18n.t("pdfToolbar.currentPage")}
                        className="tbInput"
                        id="pageNumber"
                        min="1"
                    />{" "}
                    / <span id="numberPages">{totalPages}</span>
                </label>
            </div>
            <div className="rightButtonSet">
                <button
                    type="button"
                    onClick={() => onUndo()}
                    className="tbButton fal fa-arrow-turn-left"
                    title={I18n.t("pdfToolbar.undo")}
                    id="undo"
                ></button>
                <button
                    type="button"
                    onClick={() => onRedo()}
                    className="tbButton fal fa-arrow-turn-right"
                    title={I18n.t("pdfToolbar.redo")}
                    id="redo"
                ></button>
                <button type="button" onClick={() => onOpenSidebar()} className="tbButton"
                        title={I18n.t("pdfToolbar.openSidebar")}>
                    <div className="flex items-center text text-primary">
                        <i className="fal fa-sliders"/>&nbsp;
                        <Translate value="pdfToolbar.openSidebar" className="ml-2 font-lato"/>
                    </div>
                </button>
                <button type="button" onClick={() => onCreateField()} className="tbButton"
                        title={I18n.t("pdfToolbar.createField")}>
                    <div className="flex items-center text text-primary">
                        <i className="fal fa-plus-square"/>&nbsp;
                        <Translate value="pdfToolbar.createField" className="ml-2 font-lato"/>
                    </div>
                </button>
            </div>
        </div>
    );
}