import * as React from "react";
import {useCallback, useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {
    ActionType,
    Column,
    confirmBox,
    DataType,
    FixedSelectionFilter,
    FreeTextFilter,
    GetParams,
    GlobalActionType,
    KLabel,
    KSpinner,
    KSpinnerSize,
    KTableLoader,
    Table,
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {tableGetParams} from "../utils/commons";
import {Campaign, CampaignStatus, CampaignType} from "../types/campaigns";


export interface StateProps {
    campaigns?: Campaign[];
    total: number;
    locale: string;
}

export interface DispatchProps {
    onGetCampaigns: (query: GetParams) => Promise<void>;
    onOpenCampaign: (campaignId: string) => void;
    onDeleteCampaigns: (campaignIds: string[], deleteAll: boolean, queryParams: GetParams) => Promise<void>;
    onResetTable: (id: string, p: (q: GetParams) => Promise<void>) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Campaigns: React.FC<Props> = ({onResetTable, campaigns, total, onGetCampaigns, onOpenCampaign, onDeleteCampaigns}) => {

    const campaignsRetriever = useCallback(async (innerQuery: GetParams, fixedQuery?: GetParams) => {
        await onGetCampaigns(fixedQuery ? fixedQuery : innerQuery).catch((e) => console.warn("Get campaigns error", e));
    }, [onGetCampaigns]);

    useEffect(() => {
        campaignsRetriever(tableGetParams("campaigns/list"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            if (campaigns?.length === 0) {
                const params = tableGetParams("campaigns/list");
                if ((params.filter && params.filter.length > 0) || (params.skip && params.skip > 0)) {
                    await onResetTable("campaigns/list", (q: GetParams) => campaignsRetriever(q));
                }
            }
        })().catch(console.error);
    }, [campaigns, campaignsRetriever, onResetTable]);

    const campaignTypes = new Map<string, string>(Object.values(CampaignType).map(type => {
            return [type, I18n.t(`campaigns.type.${type}`)];
        })
    );

    const campaignStatuses = new Map<string, string>(Object.values(CampaignStatus).map(status => {
            return [status, I18n.t(`campaigns.status.${status}`)];
        })
    );

    const rowAction = async (datum: Campaign) => {
        onOpenCampaign(datum.id);
    };

    const actions: ActionType<Campaign>[] = [
        {
            name: <><i className="fal fa-folder-open fa-2x"/><Translate value="campaigns.table.open"/></>,
            handler: rowAction
        },
    ];

    const globalActions: GlobalActionType[] = [
        {
            name: <>
                <div className="fal fa-trash"/>
                {" "}<Translate value="campaigns.table.delete"/></>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("campaigns.table.deleteMessage")
                };
                const deleteCampaigns = await confirmBox(confirmBoxConf);
                if (deleteCampaigns) {
                    const campaignIds = (data as Campaign[]).map(c => c.id);
                    await onDeleteCampaigns(campaignIds, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        }
    ];

    return (
        <>
            {campaigns ? (
                <>
                    {campaigns.length > 0 ? (
                        <Row>
                            <Col>
                                <Table
                                    checkboxes={true}
                                    filterDefinition={[
                                        new FreeTextFilter("name", I18n.t("campaigns.table.labels.name")),
                                        new FixedSelectionFilter("type", I18n.t("campaigns.table.labels.type"), Array.from(campaignTypes.keys()).map(t => {
                                            return {value: t, label: I18n.t(`campaigns.type.${t}`)};
                                        })),
                                        new FixedSelectionFilter("status", I18n.t("campaigns.table.labels.status"), Array.from(campaignStatuses.keys()).map(s => {
                                            return {value: s, label: I18n.t(`campaigns.status.${s}`)};
                                        })),
                                    ]}
                                    globalActions={globalActions}
                                    globalWaiter={<KSpinner size={KSpinnerSize.xxl}/>}
                                    waiter={<KSpinner/>}
                                    id={`campaigns/list`}
                                    total_count={total}
                                    loaderFunc={(q: GetParams) => campaignsRetriever(q)}
                                    data={campaigns}
                                    hideColumnSelector={true}
                                    hideFilters={false}
                                    keyField={"id"}
                                    hidePager={false}
                                    rowAction={rowAction}
                                >
                                    <Column colid="name" classes="text-start" name="campaigns.table.labels.name"
                                            type={DataType.GENERIC} sort={"name"} colspan={4}/>
                                    <Column colid="type" classes="text-end" name="campaigns.table.labels.type"
                                            type={DataType.GENERIC} sort={"type"} colspan={2}
                                            render={c => campaignTypes.get((c as Campaign).type) || ""}/>
                                    <Column colid="status" classes="text-start" name="campaigns.table.labels.status"
                                            type={DataType.GENERIC} sort={"status"} colspan={2}
                                            render={c => campaignStatuses.get((c as Campaign).status) || ""}/>
                                    <Column colid="createdAt" classes="text-end" name="campaigns.table.labels.created"
                                            type={DataType.DATE} sort={"createdAt"} colspan={2}/>
                                    <Column colid="actions" classes="text-end" name="" type={DataType.ACTIONS}
                                            actions={actions} colspan={2}/>
                                </Table>
                            </Col>
                        </Row>
                    ) : (
                        <KLabel text={<Translate value="campaigns.table.noCampaigns"/>}/>
                    )}
                </>
            ) : (
                <KTableLoader/>
            )}
        </>
    );
};
