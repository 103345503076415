import * as React from "react";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {
    ActionType,
    Column,
    confirmBox,
    DataType,
    FreeTextFilter,
    GetParams,
    GlobalActionType,
    KButton,
    KBUTTON_VARIANT,
    KModal,
    KModalSize,
    KModalVariant,
    KSpace,
    KSpinner,
    KSpinnerSize,
    KTableLoader,
    Table,
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {tableGetParams} from "../utils/commons";
import {Sign, SignStatus} from "../types/signatures";
import {AudienceTableHook, useAudienceTable} from "./AudienceTable";
import {Campaign, CampaignType, Signer} from "../types/campaigns";


export interface StateProps {
    signs?: Sign[];
    total: number;
    locale: string;
}

export interface DispatchProps {
    onGetSigns: (campaignId: string, query: GetParams) => Promise<void>;
    onAddSigners: (campaignId: string, signers: Signer[], query: GetParams) => Promise<void>;
    onDeleteSigners: (campaignId: string, signerIds: string[], deleteAll: boolean, queryParams?: GetParams) => void;
    onDownloadPdf: (campaignId: string, signatureId: string) => Promise<void>;
    onDownloadXml: (campaignId: string, signatureId: string) => Promise<void>;
    onResend: (campaignId: string, signatureId: string) => Promise<void>;
    onExportSignatures: (campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams) => Promise<void>;
    onExportSignaturePDFs: (campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams) => Promise<void>;
    onExportSignatureXMLs: (campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams) => Promise<void>;
    onInitializeSort: (loader: (q: GetParams) => Promise<void>) => Promise<void>;
}

export interface InnerProps {
    campaign: Campaign;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Signatures: React.FC<Props> = ({
                                                onInitializeSort,
                                                onResend,
                                                onDownloadXml,
                                                onDownloadPdf,
                                                signs,
                                                total,
                                                campaign,
                                                onGetSigns,
                                                onAddSigners,
                                                onDeleteSigners,
                                                onExportSignatures,
                                                onExportSignaturePDFs,
                                                onExportSignatureXMLs
                                            }) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState(false);

    const signsRetriever = async (innerQuery: GetParams, fixedQuery?: GetParams) => {
        await onGetSigns(campaign.id, fixedQuery ? fixedQuery : innerQuery).catch((e) => console.warn("Get signs error", e));
    };

    useEffect(() => {
        (async () => {
            await signsRetriever(tableGetParams("signs/list")).catch(console.log);
            if (campaign.type === CampaignType.MULTI) {
                await onInitializeSort((q: GetParams) => signsRetriever(q));
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const signatureStatuses = new Map<string, string>(Object.values(SignStatus).map(status => {
        return [ status, I18n.t(`signs.status.${status}`)];})
    );

    const globalActions: GlobalActionType[] = [];

    if (campaign.type !== CampaignType.MULTI) {
        globalActions.push(        {
                name: <>
                    <div className="fal fa-trash fa-lg"/>
                    {" "}<Translate value="signs.table.delete"/></>,
                handler: async (data: object[], config) => {
                    const confirmBoxConf = {
                        noText: I18n.t("generic.no"),
                        yesText: I18n.t("generic.yes"),
                        dark: false,
                        message: I18n.t("signs.table.deleteMessage")
                    };
                    const deleteSigners = await confirmBox(confirmBoxConf);
                    if (deleteSigners) {
                        const signerIds = (data as Sign[]).map(s => s.id);
                        await onDeleteSigners(campaign.id, signerIds, config.globalCheckedAll, config.queryParams);
                    }
                },
                bulk: true
            }
        );
    }
    if (campaign.type === CampaignType.CLOSED) {
        globalActions.push(
            {
                name: <>
                    <div className="fal fa-plus"/>
                    {" "}<Translate value="signs.table.add"/></>,
                handler: async () => {
                    audienceTableManager.reset();
                    setSubmitting(false);
                    setOpened(true);
                }
            }
        );
    }

    globalActions.push(
        {
            name: <><div className="fal fa-file-code fa-lg"/>{" "}<Translate value="signs.table.exportXMLs"/></>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("signs.table.exportMessageXMLs")
                };
                const download = await confirmBox(confirmBoxConf);
                if (download) {
                    const signerIds = (data as Sign[]).map(s => s.id);
                    await onExportSignatureXMLs(campaign.id, campaign.name, signerIds, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        },
        {
            name: <><div className="fal fa-file-pdf fa-lg"/>{" "}<Translate value="signs.table.exportPDFs"/></>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("signs.table.exportMessagePDFs")
                };
                const download = await confirmBox(confirmBoxConf);
                if (download) {
                    const signerIds = (data as Sign[]).map(s => s.id);
                    await onExportSignaturePDFs(campaign.id, campaign.name, signerIds, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        },
        {
            name: <><div className="fal fa-file-excel fa-lg"/>{" "}<Translate value="signs.table.export"/></>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("signs.table.exportMessage")
                };
                const download = await confirmBox(confirmBoxConf);
                if (download) {
                    const signerIds = (data as Sign[]).map(s => s.id);
                    await onExportSignatures(campaign.id, campaign.name, signerIds, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        }
    );

    const actions: ActionType<Sign>[] = [
        {
            name: <div style={{cursor: "pointer"}}><i className="fal fa-envelope"/>{" "}<Translate
                value="signs.table.resend"/></div>,
            handler: async (signature) => onResend(campaign.id, signature.id),
            confirmation: true,
            shouldRender: datum => (datum.status === SignStatus.SENT || datum.status === SignStatus.NOT_SENT)
                && (campaign.type === CampaignType.CLOSED || campaign.type === CampaignType.MULTI),
        },
        {
            name: <div style={{cursor: "pointer"}}><i className="fal fa-file-pdf"/>{" "}<Translate
                value="signs.table.pdf"/></div>,
            handler: async (signature) => onDownloadPdf(campaign.id, signature.id),
            shouldRender: datum => datum.status === "SIGNED",
        },
        {
            name: <div style={{cursor: "pointer"}}><i className="fal fa-file-code"/>{" "}<Translate
                value="signs.table.xml"/></div>,
            handler: async (signature) => onDownloadXml(campaign.id, signature.id),
            shouldRender: datum => datum.status === "SIGNED",
        },
    ]

    const audienceTableManager: AudienceTableHook = useAudienceTable({phoneRequired: campaign.require2fa});

    return (
        <>
            {signs ? (
                <Row>
                    <Col>
                        <Table
                            checkboxes={true}
                            filterDefinition={[
                                new FreeTextFilter("fullname", I18n.t("signs.table.labels.fullname")),
                                new FreeTextFilter("email", I18n.t("signs.table.labels.email")),
                                new FreeTextFilter("phone", I18n.t("signs.table.labels.phone")),
                            ]}
                            globalActions={globalActions}
                            globalWaiter={<KSpinner size={KSpinnerSize.xxl}/>}
                            waiter={<KSpinner/>}
                            id={`signs/list`}
                            total_count={total}
                            loaderFunc={(q: GetParams) => signsRetriever(q)}
                            data={signs}
                            hideColumnSelector={true}
                            hideFilters={false}
                            keyField={"id"}
                            hidePager={false}
                            loadInterval={10000}
                        >
                            {campaign.type === CampaignType.MULTI ? <Column colid="signerIndex" type={DataType.GENERIC} name="#" sort={"signerIndex"} colspan={1}/> : <></> }
                            <Column colid="fullName" classes="text-start" name="signs.table.labels.fullname"
                                    type={DataType.GENERIC} sort={"fullName"} colspan={3}/>
                            <Column colid="email" classes="text-end" name="signs.table.labels.email"
                                    type={DataType.GENERIC} sort={"email"} colspan={2}/>
                            <Column colid="phoneNumber" classes="text-start" name="signs.table.labels.phone"
                                    type={DataType.GENERIC} sort={"phoneNumber"} colspan={2}/>
                            <Column colid="status" classes="text-end" name="signs.table.labels.status"
                                    type={DataType.GENERIC} sort={"status"} colspan={1} render={s => signatureStatuses.get((s as Sign).status) || ""} />
                            {campaign.type === CampaignType.CLOSED
                                ? <Column colid="emailLastOpenedAt" classes="text-start" name="signs.table.labels.emailLastOpenedAt"
                                          type={DataType.DATETIME} sort={"emailLastOpenedAt"} colspan={2}/> : <Column colid="" type={DataType.GENERIC} colspan={0}/>}
                            <Column colid="actions" type={DataType.ACTIONS} actions={actions} colspan={2}/>
                        </Table>
                    </Col>
                </Row>
            ) : (
                <KTableLoader/>
            )}
            <KModal variant={KModalVariant.secondary} size={KModalSize.xl} show={opened} onHide={() => setOpened(false)}
                    header={I18n.t("signs.table.add")} footer={<></>}>
                {audienceTableManager.audienceTable}
                <KSpace spaces={2}/>
                <Row>
                    <Col lg={3} md={4} className={"text-start"}>
                        <KButton variant={KBUTTON_VARIANT.secondary}
                                 fill={true} disabled={submitting}
                                 text={<><i className="fal fa-times" />&nbsp;<Translate value={`campaignCreation.close`} /></>}
                                 onClick={() => setOpened(false)}
                        />
                    </Col>
                    <Col lg={6} md={4}/>
                    <Col lg={3} md={4} className={"text-end"}>
                        <KButton fill={true} waiting={submitting}
                                 text={<><i className="fal fa-save"/>&nbsp;<Translate value={`campaignCreation.signers.save`}/></>}
                                 onClick={async () => {
                                     const data = audienceTableManager.getData();
                                     if (data) {
                                         setSubmitting(true);
                                         await onAddSigners(campaign.id, data, tableGetParams("signs/list"));
                                         setOpened(false);
                                     }
                                 }}
                        />
                    </Col>
                </Row>
            </KModal>
        </>
    );
};
