import { GetParams } from "@kopjra/uikit";
import { connect } from "react-redux";
import { doResetCampaign } from "../actions/campaigns";
import { doResetSigners } from "../actions/signatures";
import { doDeleteCampaigns, doGetCampaigns } from "../actions/thunks/campaigns";
import { DispatchProps, StateProps, InnerProps, Campaigns } from "../components/Campaigns";
import { RootState, ThunkDispatch } from "../types";
import { routerTools } from "../utils/router";
import {doResetFilters} from "@kopjra/uikit/dist/src/lib/kptable/actions/thunks";


function mapStateToProps({ campaigns, i18n }: RootState): StateProps {
    return {
        locale: i18n.locale,
        campaigns: campaigns.list,
        total: campaigns.total
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCampaigns: (query: GetParams) => dispatch(doGetCampaigns(query)),
        onOpenCampaign: (campaignId: string) => {
            dispatch(doResetCampaign());
            dispatch(doResetSigners());
            routerTools.push(`/campaigns/${campaignId}`);
        },
        onDeleteCampaigns: (campaignIds: string[], deleteAll: boolean, queryParams: GetParams) => dispatch(doDeleteCampaigns(campaignIds, deleteAll, queryParams)),
        onResetTable: (id, loaderFunc) => dispatch(doResetFilters(id, loaderFunc)),
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Campaigns);
