import React, {FC, useEffect, useRef, useState} from "react";
import WebViewer, {WebViewerInstance} from "@pdftron/pdfjs-express";
import {COLORS, ICONS, isNumeric} from "../utils/commons";
import {PDFDopedToolbar} from "./PDFDopedToolbar";
import {KAccordion, KList, KSidebar, KSidebarCloseType, KSortableList, KSpinner, KSpinnerSize} from "@kopjra/uikit";
import {
    createAnnotations,
    createNewField,
    deleteAnnotations, flattenPdf,
    selectAnnotation, selectAnnotations,
    selectToolName,
    setupDocumentViewer,
    setupInstance,
    templateFieldToolName,
    updateAnnotations,
    validateFields
} from "../utils/pdfdoppedHelper";
import {PageMatrix, TemplateField, TemplateFieldType} from "../types/pdfdoped";
import {Translate} from "react-redux-i18n";
import {TemplateFieldEditor} from "./TemplateFieldEditor";
import {Signer} from "../types/campaigns";
import {PDFDopedSnapper} from "./PDFDopedSnapper";

export interface StateProps {
    file?: File;
    fields?: TemplateField[];
    signers?: Signer[];
    lastSigner?: string;
    campaignType?: string;
}

export interface DispatchProps {
    onSetLastSigner: (signer?: string) => void;
    onSetPageMatrices: (pageMatrices: PageMatrix[]) => void;
    onDeleteFields: (ids: string[]) => void;
    onUpdateFields: (fields: TemplateField[]) => void;
    onSetSignersOrder: (signersOrder: {email: string, idx: number}[]) => void;
}

export interface InnerProps {
    showValidation?: boolean;
    onErrors?: (hasError: boolean) => void;
    ignoreSigners?: boolean;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const PDFDopedViewer: FC<Props> = ({campaignType, onErrors, ignoreSigners, showValidation, onSetLastSigner, onSetSignersOrder, signers, onUpdateFields, onDeleteFields, onSetPageMatrices, lastSigner, file, fields}) => {
    const viewer = useRef(null);
    const [instance, setInstance] = useState<WebViewerInstance>();
    const [curPage, setCurPage] = useState<string>("1");
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showMainSidebar, setShowMainSidebar] = useState<boolean>(false);
    const selectedField = useRef<TemplateField | undefined>();
    const [selectedFields, setSelectedFields] = useState<TemplateField[]>([]);
    const curFieldValid = useRef<boolean | undefined>();
    const [triedToClose, setTriedToClose] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [snap, setSnap] = useState<boolean>(true);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            if (viewer.current && file && !instance) {
                const inst = await WebViewer({
                    path: "/pdfjsexpress",
                    licenseKey: "KrSlZ9wYTj5kayHqamRJ",
                    extension: "pdf",
                    disabledElements: [
                        "header",
                        "contextMenuPopup",
                        "annotationStyleEditButton",
                        "annotationDeleteButton",
                        "pageNavOverlay",
                        "annotationGroupButton",
                        "pageRedactionModal",
                        "ColorPickerModal",
                        "languageModal",
                        "passwordModal",
                        "filterModal",
                    ],
                }, viewer.current);

                setupInstance(inst, onUpdateFields, onDeleteFields, (field) => {
                    setTriedToClose(true);
                    if (curFieldValid.current !== false) {
                        setShow(!!(selectedField.current = field));
                        curFieldValid.current = undefined;
                        setTriedToClose(false);
                        return {ok: true, selectedField: selectedField.current};
                    } else {
                        return {ok: false, selectedField: selectedField.current};
                    }
                }, setSelectedFields);
                setupDocumentViewer(inst, setLoaded, setCurPage, onSetPageMatrices, () => ({ok: curFieldValid.current !== false}));

                setInstance(inst);
            }
            if (instance && file) {
                setLoaded(false);
                const flattenedPdf = await flattenPdf(file);
                instance.UI.loadDocument(flattenedPdf);
            }
        })().catch(console.error);
    }, [file, instance, onSetPageMatrices, onUpdateFields, onDeleteFields]);

    useEffect(() => {
        const errors = validateFields(fields, !ignoreSigners ? signers : undefined);
        setValidationErrors(errors);
        onErrors && onErrors(errors.length > 0);
    }, [fields, ignoreSigners, signers, onErrors]);

    useEffect(() => {
        if (instance && fields && loaded) {
            updateAnnotations(instance, fields);
            createAnnotations(instance, fields);
            deleteAnnotations(instance, fields);
            if (selectedField.current && !fields.some((f) => f.id === selectedField.current?.id)) {
                selectedField.current = undefined;
                curFieldValid.current = undefined;
            }
        }
    }, [instance, fields, selectedField, loaded]);

    useEffect(() => {
        return () => {
            selectedField.current = undefined;
            curFieldValid.current = undefined;
        }
    }, []);

    const accordionElements = [
        {
            title: <Translate value={"pdfSidebar.fieldsTitle"}/>,
            body: <KList<TemplateField> items={fields ? fields.map((f) => ({
                data: f,
                title: f.type === TemplateFieldType.CUSTOM && f.name ? f.name : <Translate value={`pdfViewer.${f.type}`}/>,
                required: f.required,
                subtitle: f.signerEmail || undefined,
                onSelected: () => instance && selectAnnotation(instance, f.id),
                icon: <i className={`fa ${ICONS[f.type][1]}`} style={{color: COLORS.get(f.type) || COLORS.get("DEFAULT") || ""}}/>,
                actions: [
                    {
                        type: "danger",
                        element: <i className="fal fa-trash"/>,
                        tooltip: <Translate value={"pdfSidebar.deleteFieldTip"}/>,
                        confirmation: true,
                        action: (data) => {
                            onDeleteFields([data.id]);
                            curFieldValid.current = undefined;
                        }
                    }
                ]
            })) : []}/>,
        },
    ];
    if (signers && signers.length > 0 && !ignoreSigners) {
        let sortableElements = signers.map((s, i) => ({
            id: s.internalId,
            idx: s.signerIndex || i + 1,
            content: s.email,
        }));
        sortableElements = sortableElements.sort((a, b) => a.idx - b.idx);
        accordionElements.push({
            title: <Translate value={"pdfSidebar.signersTitle"}/>,
            body: <KSortableList
                hideIndexes={true}
                elements={sortableElements}
                onChange={(elements) => {
                    const changedElements = elements.map((e, index) => ({email: e.content as string, idx: index + 1, old: e.idx})).filter((e) => e.idx !== e.old)
                    onSetSignersOrder(changedElements);
                }}
            />
        });
    }
    return file ? <>
        {validationErrors.length > 0 && showValidation && loaded? (
            <div className={"errors"}>
                <span><Translate value={"pdfViewer.error.errorsFound"}/></span>
                <ul>
                    {validationErrors.map((error, i) => <li key={i}>{error}</li>)}
                </ul>
            </div>
        ) : null}
        <div className={`external-border ${validationErrors.length > 0 && showValidation ? "error" : ""}`}>
            {loaded ? (
                <PDFDopedToolbar
                    currentPage={curPage}
                    totalPages={instance?.Core.documentViewer.getPageCount() || 0}
                    snap={snap}
                    onCurrentPageChange={page => {
                        if (isNumeric(page)) {
                            instance?.Core.documentViewer.setCurrentPage(+page, false);
                        }
                        setCurPage(page);
                    }}
                    onZoomOut={() => instance?.UI.setZoomLevel(instance?.UI.getZoomLevel() - 1 > instance?.UI.getMinZoomLevel() ? instance?.UI.getZoomLevel() - 1 : instance?.UI.getZoomLevel())}
                    onZoomIn={() => instance?.UI.setZoomLevel(instance?.UI.getZoomLevel() + 1 < instance?.UI.getMaxZoomLevel() ? instance?.UI.getZoomLevel() + 1 : instance?.UI.getZoomLevel())}
                    onUndo={() => instance?.Core.documentViewer.getAnnotationHistoryManager().undo()}
                    onRedo={() => instance?.Core.documentViewer.getAnnotationHistoryManager().redo()}
                    onCreateField={() => instance && createNewField(instance, onUpdateFields)}
                    onSetCreateTool={() => instance?.UI.setToolMode(templateFieldToolName)}
                    onSetSelectTool={() => instance?.UI.setToolMode(selectToolName)}
                    onOpenSidebar={() => {setShowMainSidebar(true); setShow(false); } }
                    onCloneSelected={() => {
                        if (selectedFields) {
                            const newlyCreatedFields = selectedFields.map((field) => createNewField(instance!, onUpdateFields, field));
                            setTimeout(() => selectAnnotations(instance!, newlyCreatedFields.map((f) => f.id)),200);
                        }
                    }}
                    onSetSnap={setSnap}
                />
            ) : null}
            <KSidebar title={<Translate value={"pdfSidebar.mainTitle"}/>} show={showMainSidebar} onClose={() => setShowMainSidebar(false)}>
                <div className={"field-accordion"}>
                    <KAccordion arrowLeft={true} actives={[0, 1]} elements={accordionElements}/>
                </div>
            </KSidebar>
            <KSidebar
                title={<Translate value="pdfSidebar.secondaryTitle"/>} show={show} closeType={KSidebarCloseType.BACK}
                onClose={() => {
                    setTriedToClose(true);
                    if (curFieldValid.current !== false) {
                        setShow(!!(selectedField.current = undefined));
                        curFieldValid.current = undefined;
                        setTriedToClose(false);
                        // deselectAllAnnotations(instance!);
                    }
                }}
            >
                {selectedField.current ?
                    <TemplateFieldEditor
                        field={selectedField.current}
                        fields={selectedFields}
                        signers={signers}
                        ignoreSigners={ignoreSigners}
                        triedToClose={triedToClose}
                        campaignType={campaignType}
                        onChange={(field) => {
                            onUpdateFields([field]);
                            if (selectedField.current?.type !== field.type) {
                                setTriedToClose(false);
                            }
                            selectedField.current = field;
                        }}
                        onDelete={(field) => {
                            onDeleteFields([field.id]);
                            setShow(!!(selectedField.current = undefined));
                            curFieldValid.current = undefined;
                        }}
                        onSetIsValid={(isValid) => {curFieldValid.current = isValid;}}
                        onSetLastSigner={onSetLastSigner}
                    /> : null}
            </KSidebar>
            <div id="pdfViewer" className="viewer" ref={viewer} style={{height: "48rem", position: "relative"}}>
                <PDFDopedSnapper selectedField={selectedField.current} fields={fields} instance={instance} snap={snap}/>
                {!loaded ? <div style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0}}>
                    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                        <KSpinner size={KSpinnerSize.xl}/>
                    </div>
                </div> : null}
            </div>
        </div>
    </> : null;
};