import React, {useState} from "react";
import {KButton, KBUTTON_SIZE, KBUTTON_VARIANT, KNavbar, KNavBrandFixed, KNavDropdown, KNavDropdownItem, KNavLangSelector, KNavLink, noop} from "@kopjra/uikit";
import {translations} from "../locale";
import {Translate} from "react-redux-i18n";
import {useAuth0} from "@auth0/auth0-react";
import {Routes, useRouter} from "../utils/router";

export interface StateProps {
    locale: string;
}

export interface DispatchProps {
    onChangeLocale: (locale: string) => void;
    onGoHome: () => void;
    onGoTemplates: () => void;
    onGoAudiences: () => void;
    onGoAccount: () => void;
    onGoCollaborators: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Toolbar: React.FC<Props> = ({locale, onGoCollaborators, onChangeLocale, onGoHome, onGoTemplates, onGoAudiences, onGoAccount}) => {
    const {isAuthenticated, loginWithRedirect, logout} = useAuth0();
    const {location} = useRouter();
    const [easter, setEaster] = useState(false);
    return !location.pathname.includes("/sign") ? (
        <KNavbar
            brand={<KNavBrandFixed iconPartial="web-sign" appName="WebSign"/>}
            rightMost={
                <KNavLangSelector  onChangeLocale={(loc) => onChangeLocale(loc)} locale={locale} translations={translations} alignRight={true}/>
            }
        >
            <KNavLink active={location.pathname === Routes.HOME} onClick={() => onGoHome()} element={<Translate value="toolbar.campaigns"/>}/>
            <KNavLink active={location.pathname === Routes.TEMPLATES} onClick={() => onGoTemplates()} element={<Translate value="toolbar.templates"/>}/>
            <KNavLink active={location.pathname === Routes.AUDIENCES} onClick={() => onGoAudiences()}
                      element={<span onClick={e => e.shiftKey && e.altKey && setEaster(!easter)}>
                          {!easter ? <Translate value="toolbar.audiences"/> : <><i className={"fal fa-toilet fa-2x"}/>&nbsp;<i className={"fal fa-toilet fa-2x"}/>&nbsp;<Translate value="toolbar.audiences"/></>
                      }</span>}
            />
            <KNavDropdown id={"user.profile-dropdown"} title={<Translate value="user.account"/>}>
                {!isAuthenticated ? (
                    <>
                        <div style={{padding: 20, width: "100%"}}>
                            <KButton text={<span><i className="fal fag fa-sign-in"/> <Translate value="user.login"/></span>} variant={KBUTTON_VARIANT.success} size={KBUTTON_SIZE.sm}
                                     onClick={() => loginWithRedirect()} fill={true}/>
                        </div>
                        <KNavDropdownItem onClick={() => noop()}>
                            <Translate value="user.support"/>
                        </KNavDropdownItem>
                     </>
                ) : (
                    <>
                        <KNavDropdownItem onClick={() => onGoAccount()}>
                            <Translate value="user.profile"/>
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => onGoCollaborators()}>
                            <Translate value="collaborators.title"/>
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => noop()}>
                            <Translate value="user.support"/>
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => logout({returnTo: window.location.origin})}>
                            <Translate value="user.logout"/>
                        </KNavDropdownItem>
                    </>
                )}
            </KNavDropdown>
        </KNavbar>
    ) : <></>;
}
