export enum TemplateFieldType {
    "EMAIL" = "EMAIL",
    "FULLNAME" = "FULLNAME",
    "PHONE" = "PHONE",
    "DATE" = "DATE",
    "SIGNATURE" = "SIGNATURE",
    "CONDITIONAL_SIGNATURE" = "CONDITIONAL_SIGNATURE",
    "CUSTOM" = "CUSTOM"
}

export interface TemplateField {
    type: TemplateFieldType
    /** name in the xml file */
    id: string
    /** title in the xml file, set to `field.type` for all fields except custom fields and conditional signature fields */
    name?: string
    /** description for conditional signature fields, correspond to text in the pdf */
    description?: string
    /** required for conditional signature fields */
    required?: boolean
    /** starting at 1. */
    page: number
    /** in pixels, in the page. */
    rect: { x: number; y: number; width: number; height: number }
    /** email of the signer if applicable*/
    signerEmail?: string;
}

export interface PageMatrix {
    data: number[]
}

export interface PdfDopedState {
    lastSigner?: string;
    file?: File;
    fields?: TemplateField[];
    pageMatrices?: PageMatrix[];
}