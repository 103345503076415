import {Core, WebViewerInstance} from "@pdftron/pdfjs-express";
import React from "react";
import {guid} from "./guid";
import {
    createTemplateFieldAnnotation,
    createTemplateFieldAnnotationClass,
    getTemplateFieldAnnotation
} from "./TemplateFieldAnnotation";
import {store} from "../index";
import {PageMatrix, TemplateField, TemplateFieldType} from "../types/pdfdoped";
import {Signer} from "../types/campaigns";
import {I18n} from "react-redux-i18n";
import {PDFDocument} from "pdf-lib";

export const templateFieldToolName = "AnnotationCreateTemplateField";
export const selectToolName = "AnnotationEdit";

function convertAnnotationToTemplateField(annotation: Core.Annotations.CustomAnnotation): TemplateField {
    return {
        type: (annotation as any).fieldType as TemplateFieldType,
        id: annotation.Id,
        page: annotation.PageNumber,
        rect: {x: annotation.X, y: annotation.Y, width: annotation.Width, height: annotation.Height},
        signerEmail: (annotation as any).email,
        name: (annotation as any).name,
        required: (annotation as any).required,
        description: (annotation as any).description,
    }
}

export async function flattenPdf(file: File): Promise<Blob> {
    const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
    const form = pdfDoc.getForm();
    form.flatten();
    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], {type: "application/pdf"});
}

export function setupDocumentViewer(instance: WebViewerInstance, setLoaded: React.Dispatch<boolean>,
                                    setCurPage: React.Dispatch<string>,
                                    onSetPageMatrices: (pageMatrices: PageMatrix[]) => void,
                                    isOK: () => {ok: boolean},
): void {
    const {documentViewer, DocumentViewer: {Events}} = instance.Core;
    documentViewer.addEventListener(Events.PAGE_NUMBER_UPDATED, (pageNumber: number) => {
        setCurPage(`${pageNumber}`);
    });
    documentViewer.addEventListener(Events.TOOL_MODE_UPDATED, (e) => {
        instance.UI.setToolMode(templateFieldToolName);
    });
    documentViewer.addEventListener(Events.DOCUMENT_LOADED, async () => {
        console.log("Document loaded");
        setLoaded(true);
        documentViewer.setFitMode(documentViewer.FitMode.FitWidth);
        instance.UI.setToolMode(templateFieldToolName);
        const pageMatrices: PageMatrix[] = [];
        for (let i = 1; i <= documentViewer.getPageCount(); i++) {
            const matrix = documentViewer.getDocument().getPageMatrix(i);
            const destructuredMatrix = Object.keys(matrix).map((key: string) => matrix[key]);
            pageMatrices.push({data: destructuredMatrix});
        }
        onSetPageMatrices(pageMatrices);
    });
    documentViewer.getTool(templateFieldToolName).addEventListener("annotationAdded", (annotation) => {
        if (isOK().ok) {
            instance.Core.annotationManager.selectAnnotation(annotation);
        } else {
            setTimeout(() => instance.Core.annotationManager.deleteAnnotation(annotation), 50);
        }
    });
}

export function selectAnnotation(instance: WebViewerInstance, id: string): void {
    const {annotationManager} = instance.Core;
    const annotation = annotationManager.getAnnotationsList().find((a) => a.Id === id);
    annotationManager.deselectAllAnnotations();
    if (annotation) {
        annotationManager.jumpToAnnotation(annotation, {isSmoothScroll: true, verticalOffset: "25%"});
        annotationManager.selectAnnotation(annotation);
    }
}

export function selectAnnotations(instance: WebViewerInstance, ids: string[]): void {
    const {annotationManager} = instance.Core;
    const annotations = annotationManager.getAnnotationsList().filter((a) => ids.includes(a.Id));
    annotationManager.deselectAllAnnotations();
    for (const annotation of annotations) {
        annotationManager.selectAnnotation(annotation);
    }
}

export function deselectAllAnnotations(instance: WebViewerInstance): void {
    const {annotationManager} = instance.Core;
    annotationManager.deselectAllAnnotations();
}

export function createAnnotations(instance: WebViewerInstance, fields: TemplateField[]): void {
    const {annotationManager} = instance.Core;
    // Create new annotations
    let lastNewAnnotation: Core.Annotations.CustomAnnotation | undefined;
    const annotations = annotationManager.getAnnotationsList();
    const newFields = fields.filter((f) => !annotations.some((a) => a.Id === f.id));
    for (const field of newFields) {
        const annotation = createTemplateFieldAnnotation(field);
        if (annotation) {
            annotationManager.addAnnotation(annotation);
            annotationManager.redrawAnnotation(annotation);
            setTimeout(() => annotationManager.redrawAnnotation(annotation), 200);
            lastNewAnnotation = annotation;
        }
    }

    if (lastNewAnnotation) {
        annotationManager.deselectAllAnnotations();
        // annotationManager.selectAnnotation(lastNewAnnotation);
    }
}

export function updateAnnotations(instance: WebViewerInstance, fields: TemplateField[]): void {
    const {annotationManager} = instance.Core;
    // Update existing annotations
    const annotations = annotationManager.getAnnotationsList().filter((a) => fields.some((f) => f.id === a.Id));
    for (const annotation of annotations) {
        const field = fields.find((f) => f.id === annotation.Id);
        if (field) {
            const updated = (annotation as any).update(field);
            if (updated) {
                annotationManager.redrawAnnotation(annotation);
            }
        }
    }
}

export function deleteAnnotations(instance: WebViewerInstance, fields: TemplateField[]): void {
    const {annotationManager} = instance.Core;
    // Delete annotations
    const annotations = annotationManager.getAnnotationsList().filter((a) => a.Id && !fields.some((f) => f.id === a.Id));
    for (const annotation of annotations) {
        annotationManager.deleteAnnotation(annotation);
    }
}

function setupTemplateFieldAnnotationTool(instance: WebViewerInstance): void {
    const {GenericAnnotationCreateTool} = instance.Core.Tools;
    class TemplateFieldCreateTool extends GenericAnnotationCreateTool {
        constructor(docViewer: Core.DocumentViewer) {
            super(docViewer, getTemplateFieldAnnotation());
        }
    }
    const templateFieldTool = new TemplateFieldCreateTool(instance.Core.documentViewer);
    instance.UI.registerTool({
        toolName: templateFieldToolName,
        toolObject: templateFieldTool,
        buttonImage: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">' +
            '<path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>' +
            '<path fill="none" d="M0 0h24v24H0V0z"/>' +
            '</svg>',
        buttonName: 'templateFieldToolButton',
        tooltip: 'Template Field'
    }, getTemplateFieldAnnotation() as any);
    instance.UI.setHeaderItems((header) => {
        header.getHeader('toolbarGroup-Shapes').get('freeHandToolGroupButton').insertBefore({
            type: 'toolButton',
            toolName: templateFieldToolName
        });
    });
}

export function setupInstance(
    instance: WebViewerInstance,
    onUpdatedFields: (fields: TemplateField[]) => void,
    onDeleteFields: (ids: string[]) => void,
    setSelectedField: (field: TemplateField | undefined) => {ok: boolean, selectedField?: TemplateField},
    setSelectedFields:  React.Dispatch<React.SetStateAction<TemplateField[]>>,
): void {
    const {Core: {annotationManager, AnnotationManager: {Events}}, UI} = instance;
    annotationManager.disableFreeTextEditing();
    UI.disableFeatures([
        UI.Feature.Annotations,
        UI.Feature.TextSelection,
        // UI.Feature.Copy,
        UI.Feature.Download,
        UI.Feature.FilePicker,
        UI.Feature.ThumbnailMerging,
        UI.Feature.MouseWheelZoom,
        UI.Feature.Search,
        UI.Feature.MultiTab
    ]);
    UI.setMinZoomLevel(1);
    UI.setMaxZoomLevel(5);
    UI.addEventListener("loaderror", (e) => {
        console.error(e);
    });
    createTemplateFieldAnnotationClass(instance);
    setupTemplateFieldAnnotationTool(instance);
    annotationManager.addEventListener(Events.ANNOTATION_CHANGED, (annotations: Core.Annotations.CustomAnnotation[], action: string) => {
        switch (action) {
            case "add":
            case "modify":
                onUpdatedFields(annotations.filter((a) => (a as any).isConsistent()).map(convertAnnotationToTemplateField));
                break;
            case "delete":
                onDeleteFields(annotations.map((a: Core.Annotations.CustomAnnotation) => a.Id));
                break;
        }
    });
    annotationManager.addEventListener(Events.ANNOTATION_SELECTED, (annotations: Core.Annotations.CustomAnnotation[], action: string) => {
        switch (action) {
            case "selected":
                const annotation = annotations[annotations.length - 1];
                const field = store.getState().pdfdoped.fields?.find((f) => f.id === annotation.Id)
                const selectedFieldOutput = setSelectedField(field);
                if (!selectedFieldOutput.ok) {
                    const toDeselect = annotations.filter((a) => a.Id !== selectedFieldOutput.selectedField?.id);
                    annotationManager.deselectAnnotations(toDeselect);
                } else {
                    const selectedAnnotations = annotationManager.getSelectedAnnotations();
                    const fields = store.getState().pdfdoped.fields?.filter((f) => selectedAnnotations.some((a) => a.Id === f.id)) || [];
                    setSelectedFields(fields);
                }
                break;
            case "deselected":
                const selectedField = setSelectedField(undefined);
                if (!selectedField.ok) {
                    const toSelect = annotations.find((a) => a.Id === selectedField.selectedField?.id);
                    if (toSelect) {
                        annotationManager.selectAnnotation(toSelect);
                    }
                }
                break;
        }
    });

}

function getPageCoordinatesAt(x: number, y: number, instance: WebViewerInstance): { x: number; y: number; page: number } {
    const viewer = instance.Core.documentViewer;
    const doc = viewer.getDocument()
    const displayMode = viewer.getDisplayModeManager().getDisplayMode()
    let result: { x: number; y: number; page: number } = {x, y, page: 1}
    for (let page = 1; page <= doc.getPageCount(); page++) {
        // get the top left point of the page, in window coordinate
        const coords = displayMode.pageToWindow({x: 0, y: 0}, page)
        if (coords.y <= y) {
            // add the page x for zoomed out (otherwise 0 0 is outside the page)
            const pageCoords = displayMode.windowToPage({x: coords.x + x, y}, page)
            result.x = pageCoords.x
            result.y = pageCoords.y
            result.page = page
        } else {
            return result
        }
    }
    return result
}

export function createNewField(instance: WebViewerInstance, onUpdateFields: (fields: TemplateField[]) => void, cloneFrom?: TemplateField): TemplateField {
    const scrollElement = instance.Core.documentViewer.getScrollViewElement();
    const top = scrollElement.scrollTop || 0;
    const left = scrollElement.scrollLeft || 0;
    const {x, y, page} = getPageCoordinatesAt(
        left + 10,
        top + 10,
        instance,
    );

    const templateField: TemplateField = {
        type: cloneFrom ? cloneFrom.type : TemplateFieldType.SIGNATURE,
        id: guid(),
        page: cloneFrom ? cloneFrom.page : page,
        rect: {
            x: cloneFrom ? (cloneFrom.rect.x + 10) : x,
            y: (cloneFrom ? cloneFrom.rect.y : y) + 10,
            width: cloneFrom ? cloneFrom.rect.width : 150,
            height: cloneFrom ? cloneFrom.rect.height : 50
        },
        name: cloneFrom ? cloneFrom.name : undefined,
        description: cloneFrom ? cloneFrom.description : undefined,
        required: cloneFrom ? cloneFrom.required : false,
        signerEmail: cloneFrom ? cloneFrom.signerEmail : store.getState().pdfdoped.lastSigner
    }
    onUpdateFields([templateField]);
    return templateField;
}

export function validateFields(fields?: TemplateField[], signers?: Signer[] | undefined): string[] {
    const errors: Set<string> = new Set();
    if (!fields || fields.length === 0) {
        errors.add(I18n.t("pdfViewer.error.noFields"));
    } else {
        if (signers) {
            const presentSigners: Set<string> = new Set();
            for (const field of fields) {
                if (!field.signerEmail) {
                    errors.add(I18n.t("pdfViewer.error.fieldsWithNoSigner"));
                } else if (field.type === TemplateFieldType.SIGNATURE || field.type === TemplateFieldType.CONDITIONAL_SIGNATURE) {
                    presentSigners.add(field.signerEmail);
                }
            }
            const signersWithoutFields = signers.filter((signer) => !presentSigners.has(signer.email));
            if (signersWithoutFields.length > 0) {
                errors.add(I18n.t("pdfViewer.error.signerWithNoFields", {signers: signersWithoutFields.map((s) => s.email).join(", ")}));
            }
        }
    }
    return Array.from(errors.values());
}