import { ThunkResult } from "../../types";
import { Dispatch } from "redux";
import { RootAction } from "../index";
import { cancelTemplate, gotCurFile, gotTemplate, gotTemplates } from "../templates";
import { GetParams, setAlert } from "@kopjra/uikit";
import { getTemplateFile, getTemplate, getTemplates, saveTemplate, transformToTemplateParams, saveTemplateFile, deleteTemplates, updateTemplate } from "../../api/templates";
import { NewTemplate } from "../../types/templates";
import { I18n } from "react-redux-i18n";
import { routerTools } from "../../utils/router";


export const doGetTemplateWithFile = (templateId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const template = await getTemplate(templateId);
    if (template) {
        const file = await getTemplateFile(templateId);
        if (file) {
            dispatch(gotTemplate(template));
            dispatch(gotCurFile(file));
        }
    }
};

export const doGetTemplates = (query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotTemplates(await getTemplates(transformToTemplateParams(query))));
};

export const doSaveTemplate = (newTemplate: NewTemplate): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => { 
    const template = newTemplate.isNew ? await saveTemplate(newTemplate) : await updateTemplate(newTemplate);
    if (template) {
        await saveTemplateFile(template.id, newTemplate.file);
        routerTools.push(`/templates`);
        dispatch(setAlert(I18n.t("alerts.infos.newTemplate"), "info"));
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.newTemplate"), "error"));
    }
};
export const doDeleteTemplates = (templateIds: string[], deleteAll: boolean, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => { 
    const deleted = await deleteTemplates(templateIds, deleteAll, queryParams);
    if (deleted) {
        dispatch(setAlert(I18n.t("alerts.infos.deleteTemplates"), "info"));
        for (const id of templateIds) {
            dispatch(cancelTemplate(id));
        }
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.deleteTemplates"), "error"));
    }
}